
export const cols = [
  {
    prop: 'productSkuCode',
    label: 'SKU',
    minWidth: '200'
  },
  {
    prop: 'productStructName',
    label: '款式',
    minWidth: '200'
  },
  {
    prop: 'productSizeName',
    label: '尺码',
    minWidth: '200'
  },
  {
    prop: 'productCount',
    label: '数量',
    minWidth: '200'
  },
  {
    prop: 'unitPrice',
    label: '单价',
    minWidth: '200'
  },
  {
    prop: 'totalPrice',
    label: '小计',
    minWidth: '200'
  }
]
